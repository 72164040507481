
.Slideshow-slideshowAndActions-slideshow
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05)
    background: white
    border-radius: 5px
    // width: 96%
    // width: 600px
    // height: 400px
    // height: calc(337.5px + 45px)
    position: relative
    margin-right: 24px
    min-height: 450px
    margin-bottom: 48px

    .Slideshow-slideshowAndActions-slideshow-slideWrapper
        // height: 355px
        height: calc(100% - 45px)
        // height: 337.5px // 9/16th of 600
        position: absolute
        width: 100%
        background-color: black
        display: flex

        .Slideshow-slideshowAndActions-slideshow-slideWrapper-background
            width: 100%
            height: 100%
            position: absolute

        .Slideshow-slideshowAndActions-slideshow-slides
            position: relative
            margin: auto
            // background-color: #fff

    .Slideshow-slideshowAndActions-slideshow-controls
        position: absolute
        bottom: 0px
        height: 45px
        background-color: #f2f2f2
        width: 100%
        line-height: 45px
        user-select: none

        svg
            width: 24px
            height: 24px
            fill: #afafaf
            vertical-align: middle
            display: inline

        .Slideshow-slideshowAndActions-slideshow-controls-slides-backward,
        .Slideshow-slideshowAndActions-slideshow-controls-slides-forward
            cursor: pointer

            &:hover
                background-color: #e2e2e2

                svg
                    fill: #444

        .Slideshow-slideshowAndActions-slideshow-controls-slides
            width: 85%
            text-align: center
            float: left

            .Slideshow-slideshowAndActions-slideshow-controls-slides-backward
                width: 50%
                float: left

                svg
                    transform: rotate(180deg)

            .Slideshow-slideshowAndActions-slideshow-controls-slides-forward
                width: 50%
                float: left

        .Slideshow-slideshowAndActions-slideshow-controls-screen
            width: 15%
            float: left
            height: 100%
            text-align: right

            button
                margin-right: 12px

                &:hover
                    svg
                        fill: #444


.Slideshow-slideshowAndActions-slideshow-slides
    position: relative
    overflow: hidden

    .Slideshow-slideshow-slides-slide
        width: 100%
        height: 100%
        position: absolute
        overflow: hidden

        &.hide
            visibility: hidden

        &.background-less
            background-color: #fff

        .Slideshow-slideshow-slides-slide-debug
            opacity: 0.2
            font-size: 36px
            bottom: 12px
            right: 12px
            position: absolute

        .thing
            transform-origin: top left
            font-size: 0
            line-height: 1
            // white-space: nowrap
            position: absolute

            ul, ol
                padding-left: 18px

            ol li
                list-style-type: decimal

            ul li
                list-style-type: disc

            .thing-button
                background-color: #006E78
                color: #fff
                padding: 12px
                font-size: 15px
                border-radius: 3px

                &:hover
                    background-color: #003338

        .Slideshow-slideshow-slides-slide-popupbackground
            width: 100%
            height: 100%
            z-index: 1
            background-color: rgba(255, 255, 255, 0.85)
            position: absolute
