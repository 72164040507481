.Asset-move
    display: none

.Asset:hover
    .Asset-move
        display: inline-block

.textAlign-center .public-DraftStyleDefault-ltr
    text-align: center


.Asset.bullets
    .public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul
        margin-top: 0px
